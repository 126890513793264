export default {
  v: '5.9.4',
  fr: 25,
  ip: 48,
  op: 94,
  w: 96,
  h: 96,
  ddd: 0,
  assets: [],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 4,
      td: 1,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [48, 48, 0], ix: 2, l: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 }
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [-9.887, -10.282],
                    [12.756, 13.101],
                    [-16.637, -19.908],
                    [-5.167, 6.333]
                  ],
                  o: [
                    [0, 0],
                    [8.333, 8.667],
                    [-18.5, -19],
                    [19.5, 23.333],
                    [5.167, -6.333]
                  ],
                  v: [
                    [9, -18.333],
                    [-13.333, 13.833],
                    [19.5, -28.5],
                    [-25.167, 23.833],
                    [48.167, -17.667]
                  ],
                  c: false
                },
                ix: 2
              },
              hd: false
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0.730743408203, 0.730743408203, 0.730743408203, 1],
                ix: 3
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 11, ix: 5 },
              lc: 2,
              lj: 1,
              ml: 4,
              bm: 0,
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 }
            }
          ],
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          hd: false
        },
        {
          ty: 'tm',
          s: {
            a: 1,
            k: [
              {
                i: { x: [0.833], y: [0.833] },
                o: { x: [0.167], y: [0.167] },
                t: 48,
                s: [89]
              },
              {
                i: { x: [0.833], y: [0.833] },
                o: { x: [0.167], y: [0.167] },
                t: 60.6,
                s: [53]
              },
              { t: 70.2001953125, s: [91] }
            ],
            ix: 1
          },
          e: { a: 0, k: 0, ix: 2 },
          o: {
            a: 1,
            k: [
              {
                i: { x: [0.41], y: [1] },
                o: { x: [0.59], y: [0] },
                t: 48,
                s: [0]
              },
              { t: 75, s: [-360] }
            ],
            ix: 3
          },
          m: 1,
          ix: 2,
          hd: false
        }
      ],
      ip: 0,
      op: 1000,
      st: 0,
      ct: 1,
      bm: 0
    },
    {
      ddd: 0,
      ind: 2,
      ty: 4,
      tt: 1,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [46.907, 46.5, 0], ix: 2, l: 2 },
        a: { a: 0, k: [-1.093, -1.5, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 }
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, -3.314],
                    [-3.441, 0],
                    [0, 0],
                    [0, 0.368],
                    [0.382, 0],
                    [0, 0],
                    [0, 2.577],
                    [-2.676, 0],
                    [0, 0],
                    [0, -1.595],
                    [1.657, 0],
                    [0, 0],
                    [0, 0.614],
                    [-0.637, 0],
                    [0, 0],
                    [0, 0.368],
                    [0, 0.008],
                    [0.374, 0],
                    [0, 0],
                    [0, -1.35],
                    [-1.402, 0],
                    [0, 0],
                    [0, 2.332],
                    [2.422, 0]
                  ],
                  o: [
                    [-3.441, 0],
                    [0, 3.314],
                    [0, 0],
                    [0.382, 0],
                    [0, -0.368],
                    [0, 0],
                    [-2.676, 0],
                    [0, -2.577],
                    [0, 0],
                    [1.657, 0],
                    [0, 1.595],
                    [0, 0],
                    [-0.637, 0],
                    [0, -0.614],
                    [0, 0],
                    [0.382, 0],
                    [0, -0.008],
                    [-0.013, -0.357],
                    [0, 0],
                    [-1.402, 0],
                    [0, 1.35],
                    [0, 0],
                    [2.422, 0],
                    [0, -2.332],
                    [0, 0]
                  ],
                  v: [
                    [-2.769, -6],
                    [-9, 0],
                    [-2.769, 6],
                    [4.352, 6],
                    [5.044, 5.333],
                    [4.352, 4.667],
                    [-2.769, 4.667],
                    [-7.615, 0],
                    [-2.769, -4.667],
                    [4.615, -4.667],
                    [7.615, -1.778],
                    [4.615, 1.111],
                    [-2.901, 1.111],
                    [-4.055, 0],
                    [-2.901, -1.111],
                    [4.352, -1.111],
                    [5.044, -1.778],
                    [5.044, -1.802],
                    [4.352, -2.444],
                    [-2.901, -2.444],
                    [-5.44, 0],
                    [-2.901, 2.444],
                    [4.615, 2.444],
                    [9, -1.778],
                    [4.615, -6]
                  ],
                  c: true
                },
                ix: 2
              },
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.086274512112, 0.0941176489, 0.098039217293, 1],
                ix: 4
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [400, 400], ix: 3 },
              r: { a: 0, k: -45, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 }
            }
          ],
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          hd: false
        },
        {
          ty: 'tm',
          s: { a: 0, k: 0, ix: 1 },
          e: { a: 0, k: 100, ix: 2 },
          o: { a: 0, k: 0, ix: 3 },
          m: 1,
          ix: 2,
          hd: false
        }
      ],
      ip: 0,
      op: 1000,
      st: 0,
      ct: 1,
      bm: 0
    },
    {
      ddd: 0,
      ind: 3,
      ty: 4,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [46.907, 46.5, 0], ix: 2, l: 2 },
        a: { a: 0, k: [-1.093, -1.5, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 }
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, -3.314],
                    [-3.441, 0],
                    [0, 0],
                    [0, 0.368],
                    [0.382, 0],
                    [0, 0],
                    [0, 2.577],
                    [-2.676, 0],
                    [0, 0],
                    [0, -1.595],
                    [1.657, 0],
                    [0, 0],
                    [0, 0.614],
                    [-0.637, 0],
                    [0, 0],
                    [0, 0.368],
                    [0.382, 0],
                    [0, 0],
                    [0, -1.35],
                    [-1.402, 0],
                    [0, 0],
                    [0, 2.332],
                    [2.422, 0]
                  ],
                  o: [
                    [-3.441, 0],
                    [0, 3.314],
                    [0, 0],
                    [0.382, 0],
                    [0, -0.368],
                    [0, 0],
                    [-2.676, 0],
                    [0, -2.577],
                    [0, 0],
                    [1.657, 0],
                    [0, 1.595],
                    [0, 0],
                    [-0.637, 0],
                    [0, -0.614],
                    [0, 0],
                    [0.382, 0],
                    [0, -0.368],
                    [0, 0],
                    [-1.402, 0],
                    [0, 1.35],
                    [0, 0],
                    [2.422, 0],
                    [0, -2.332],
                    [0, 0]
                  ],
                  v: [
                    [-2.769, -6],
                    [-9, 0],
                    [-2.769, 6],
                    [4.352, 6],
                    [5.044, 5.333],
                    [4.352, 4.667],
                    [-2.769, 4.667],
                    [-7.615, 0],
                    [-2.769, -4.667],
                    [4.615, -4.667],
                    [7.615, -1.778],
                    [4.615, 1.111],
                    [-2.901, 1.111],
                    [-4.055, 0],
                    [-2.901, -1.111],
                    [4.352, -1.111],
                    [5.044, -1.778],
                    [4.352, -2.444],
                    [-2.901, -2.444],
                    [-5.44, 0],
                    [-2.901, 2.444],
                    [4.615, 2.444],
                    [9, -1.778],
                    [4.615, -6]
                  ],
                  c: true
                },
                ix: 2
              },
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.23137254902, 0.341176470588, 0.827450980392, 1],
                ix: 4
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [400, 400], ix: 3 },
              r: { a: 0, k: -45, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 }
            }
          ],
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          hd: false
        },
        {
          ty: 'tm',
          s: { a: 0, k: 0, ix: 1 },
          e: { a: 0, k: 100, ix: 2 },
          o: { a: 0, k: 0, ix: 3 },
          m: 1,
          ix: 2,
          hd: false
        }
      ],
      ip: 0,
      op: 1000,
      st: 0,
      ct: 1,
      bm: 0
    }
  ],
  markers: []
};
